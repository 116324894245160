$(function () {
    window.do_notification = function (message, type) {
        if (typeof type === 'undefined')
            msg_type = 'success';
        else
            msg_type = 'danger';

        $.notify({
            icon: "notifications",
            message: message,
        }, {
            z_index: 10000,
            type: msg_type,
            timer: 4000,
            placement: {
                from: 'top',
                align: 'right'
            }
        });
    };
    $('.oi-by-room').click(function () {
        if ($('.oi-by-rate').hasClass('active')) {
            $('.oi-by-rate').removeClass('active');
            $('.oi-by-room').addClass('active');
        }
        refreshlist();
    });
    $('.oi-by-rate').click(function () {
        if ($('.oi-by-room').hasClass('active')) {
            $('.oi-by-room').removeClass('active');
            $('.oi-by-rate').addClass('active');
        }
        refreshlist();
    });
//    $('#currency').chosen({search_contains: true});
    $('#currency').change(function () {
        var cr = $(this).val();
        var url = '/changecurrency?cur=' + cr;
        $.ajax({
            url: url,
            type: 'GET',
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                alert('currency not support');
                location.reload();
            }
        });
    });


}
);
$(".add-on-item").on("click", function () {
    var qfield=$(this).parent().find("input[name='quantity']");
    var quantity = qfield.val();
    var btn=$(this);
    var addon=$(this).attr('data-addon');
     var url = '/ajax/addtoselection?addon=' + addon+'&quantity='+quantity;
        $.ajax({
            url: url,
            type: 'GET',
            success: function (data) {
               btn.text('ADDED/REMOVE-ITEM');
               qfield.attr('disabled','disabled');
                btn.removeClass('add-on-item');
                btn.addClass('remove-on-item');
            },
            error: function (data) {
                alert('Failed to add selection');
                location.reload();
            }
        });
});
$(document).on('click', ".remove-on-item",function () {
     var addon=$(this).attr('data-addon');
     var qfield=$(this).parent().find("input[name='quantity']");
     var btn=$(this);
     var url = '/ajax/removeselection?addon=' + addon;
        $.ajax({
            url: url,
            type: 'GET',
            success: function (data) {
               btn.text('ADD TO CHECKOUT');
                btn.removeClass('remove-on-item');
                btn.addClass('add-on-item');
                qfield.removeAttr('disabled');
            },
            error: function (data) {
                alert('Failed to remove selection');
                location.reload();
            }
        });
});
