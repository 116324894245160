$(document).ready(function () {

    //mobile menu
    $(".oi-menu-btn button").click(function () {
        $(".oi-menu").slideToggle("slow");
    });

    //Date picker
    $("#arrive").datepicker({
        minDate: 0,
        dateFormat: "dd/MM",
        onClose: function (dateText) {
            $('#day').html(dateText.split('/')[0]);
            $('#month').html(dateText.split('/')[1]);
            //$('#year').html( dateText.split('/')[2]);
        }
    });


    $("#arrive2").datepicker({
        minDate: 0,
        dateFormat: "dd/MM",
        onClose: function (dateText) {
            $('#day2').html(dateText.split('/')[0]);
            $('#month2').html(dateText.split('/')[1]);
            //$('#year').html( dateText.split('/')[2]);
        }
    });


    //mobile menu
    $(".oi-mdfy-open").click(function () {
        $(".oi-mdfy-items").slideToggle("slow");
        return false
    });

    //date picker
    $("#oi-arvl").datepicker({
        dateFormat: "dd/mm/yy",
        minDate: 0,
        onSelect: function (date) {
            var date2 = $('#oi-arvl').datepicker('getDate');
            date2.setDate(date2.getDate() + 1);
            $('#oi-dptr').datepicker('setDate', date2);
            //sets minDate to dt1 date + 1
            $('#oi-dptr').datepicker('option', 'minDate', date2);
        }
    });

    $("#oi-dptr").datepicker({
        dateFormat: "dd/mm/yy",
        minDate: 0,
        onClose: function () {
            var dt1 = $('#oi-arvl').datepicker('getDate');
            /*console.log(dt1);*/
            var dt2 = $('#oi-dptr').datepicker('getDate');
            if (dt2 <= dt1) {
                var minDate = $('#oi-dptr').datepicker('option', 'minDate');
                $('#oi-dptr').datepicker('setDate', minDate);
            }
        }
    });




    $(function () {
        $('.oi-tooltip').tipsy({fade: true, gravity: 's'});
    });

   $(document).on("click", '.oi-view-rates', function(event) { 
        var elem=$(this);
        $(this).parent().parent().next().next().slideToggle("slow");
        elem.text(elem.text() == 'VIEW AVAILABLE RATES' ? "HIDE AVAILABLE RATES" : "VIEW AVAILABLE RATES").toggleClass('active');
    });

   var currencyName=$('#currencyName').val();


    $("#slider-range").slider({
        range: true,
        min: 0,
        max: 2400,
        values: [0, 2400],
        slide: function (event, ui) {
            $( "#amount" ).val( currencyName +" " + ui.values[ 0 ] + " - " + currencyName +" "+ ui.values[ 1 ] );
            $( "#filter_amount" ).val(  ui.values[ 0 ] + " - "  + ui.values[ 1 ] );
        },
        stop: function (event, ui) {
            refreshlist();
        }

    });

    $( "#amount" ).val( currencyName +" "+ $( "#slider-range" ).slider( "values", 0 ) +
      " - "+currencyName+" " + $( "#slider-range" ).slider( "values", 1 ) );
     $( "#filter_amount" ).val(  $( "#slider-range" ).slider( "values", 0 ) +
      " - "+ $( "#slider-range" ).slider( "values", 1 ) );



    $(".oi-refine-btn").click(function () {
        $(".oi-all-refine-results").slideToggle("slow");
        $(this).hide();
    });

    $(".oi-close").click(function () {
        $(".oi-all-refine-results").slideToggle("slow");
        $('.oi-refine-btn').show();
        return false;
    });

    //custom select box
    $(".oi-ibe-slct").each(function () {
        $(this).wrap("<span class='select-wrapper'></span>");
        $(this).after("<span class='holder'></span>");

    });
    $(".oi-ibe-slct").change(function () {
        var selectedOption = $(this).find(":selected").text();
        $(this).next(".holder").text(selectedOption);
    }).trigger('change');

    $('.oi-ibe-slct').each(function ( ) {
        if ($(this).hasClass('oi-req'))
        {
            $(this).next('.holder').addClass('oi-req');
        }

    });



});


jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
jQuery('.quantity').each(function () {
    var spinner = jQuery(this),
            input = spinner.find('input[type="number"]'),
            btnUp = spinner.find('.quantity-up'),
            btnDown = spinner.find('.quantity-down'),
            min = input.attr('min'),
            max = input.attr('max');

    btnUp.click(function () {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue + 1;
        }
        var isDisabled = spinner.find("input").is(':disabled');
        if(!isDisabled){
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
        }
    });

    btnDown.click(function () {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue - 1;
        }
        var isDisabled = spinner.find("input").is(':disabled');
        if(!isDisabled){
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
         }
    });

});


$(".oi-view-rms").click(function () {
    $(this).parent().next().next().slideToggle("slow");
    $(this).text($(this).text() == 'VIEW AVAILABILE ROOM' ? "HIDE AVAILABILE ROOM" : "VIEW AVAILABILE ROOM").toggleClass('active');
});


$(".more").click(function () {
    var elem=$(this);
    $(this).parent().find('.oi-more-details').slideToggle("slow");

    if(elem.hasClass('packagemore'))
    {
     $(this).text($(this).text() == 'More about this package' ? "Read less" : "More about this package").toggleClass('active');
     console.log('yes');
    }
    else
    {
      $(this).text($(this).text() == 'More about this room' ? "Read less" : "More about this room").toggleClass('active');  
      console.log('no');
    }


    return false;
});


